import { DetailRow } from "./models/detail-list.model";

export function buildKeyValue(data: any, keyEntity: any): DetailRow[] {
  if (!Object.keys(data).length || !Object.keys(keyEntity).length) {
    return [];
  }
  const result: DetailRow[] = [];
  if (Array.isArray(data)) {
    data.forEach(item => {
      Object.keys(keyEntity).forEach(key => {
        result.push(
          { key: toTitleCase(key), value: item[key] }
        );
      });
    });
    return result;
  }
  Object.keys(keyEntity).forEach(key => {
    result.push(
      { key: toTitleCase(key), value: data[key] }
    );
  });
  return result;
}

export function toTitleCase(str: string): string {
  return str?.split(' ')
    .map(strParts => {
      if (strParts !== '') {
        return strParts[0].toUpperCase() + strParts.substring(1).toLowerCase();
      }
      return '';
    })
    .join(' ');
}