
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { SidenavHeaderConfig, SidenavFooterConfig, SidenavMenuConfig } from "@app/shared/config/sidenav.config";
import { environment } from '@environments/environment';
import { PermissionsService } from '@zonar-ui/auth';
import { Permissions } from './core/consts/permission.const';
import { SidenavParams } from '@zonar-ui/sidenav/lib/sidenav/interfaces';
import { DataDogService } from './core/services/datadog.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  //mock sidenav
  isSidenavOpen: boolean = true;
  sidenavHeader = SidenavHeaderConfig;
  sidenavMenu: any[] = SidenavMenuConfig;
  sidenavFooter = SidenavFooterConfig;
  sidenavParams: SidenavParams = {
    mobileOpened: true,
    expanded: true,
    lockExpansion: false,
    useDefaultHelpItem: false,
    hideChangeCompanyButton: false,
    footerOffset: false,
    suppressChangeCompanyDialog: true
  };
  userProvisioningSidenavItem: any = {
    text: 'User Provisioning',
    icon: 'home',
    routerLink: '/users',
    route: 'users',
    routeLevel: 2,
    isExpanded: false,
    children: [],
  };

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private permissionsService: PermissionsService,
    private datadog: DataDogService,
  ) {
    this.renderer.addClass(document.body, 'zonar-default-theme');
  }

  ngOnInit(): void {
    this.permissionsService.hasPermission(Permissions.ADMIN_PROFILES).subscribe(isAdminProfile => {
      if (isAdminProfile) {
        this.sidenavMenu.find(item => item.appId === environment.auth.applicationId)?.children.push(this.userProvisioningSidenavItem);
      }
    });
  }


  // #region Side Nav Functions
  onSidenavToggled(event: boolean) {
    // TODO
  }

  onSideNavMobileMenuButtonToggled(event: boolean) {
    this.sidenavParams.mobileOpened = true;
  }

  setCurrentCompanyByFormSelection(event: any) {
    // TODO
  }
}
