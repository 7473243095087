/* angular */
import { DetailRow } from '@app/core/models/detail-list.model';
import { UserProfile } from '@app/core/models/user.model';
import { createAction, props } from '@ngrx/store';
import { User } from 'user-api-sdk/lib/models/user';

/* action types */
export enum ActionTypes {
  InitUsers = '[USERS] InitUsers',
  GetUserByEmail = '[USERS] GetUserByEmail',
  GetUserByEmailSuccessfully = '[USERS] GetUserByEmailSuccessfully',
  GetUserByEmailFailure = '[USERS] GetUserByEmailFailure',
  CreateUserProfile = '[USERS] CreateUserProfile',
  CreateUserProfileSuccessfully = '[USERS] CreateUserProfileSuccessfully',
  CreateUserProfileFailure = '[USERS] CreateUserProfileFailure',
  ResetUserState = '[USERS] ResetUserState',
  SaveM2MToken = '[USERS] SaveM2MToken',
}

/* actions */
export const initUsers = createAction(ActionTypes.InitUsers);
export const getUserByEmail = createAction(ActionTypes.GetUserByEmail,
  props<{ payload: { email: string | null } }>());
export const getUserByEmailSuccessfully = createAction(ActionTypes.GetUserByEmailSuccessfully,
  props<{
    payload: {
      userEditing: {
        userInfo: User | null,
        userDetailsList: DetailRow[] | null
      }
    }
  }>());
export const getUserByEmailFailure = createAction(ActionTypes.GetUserByEmailFailure,
  props<{ payload: { error: any } }>());
export const createUserProfile = createAction(ActionTypes.CreateUserProfile,
  props<{ payload: { userProfile: UserProfile, newToken: string } }>());
export const createUserProfileSuccessfully = createAction(ActionTypes.CreateUserProfileSuccessfully,
  props<{ payload: { userProfile: UserProfile } }>());
export const createUserProfileFailure = createAction(ActionTypes.CreateUserProfileFailure,
  props<{ payload: { error: any } }>());
export const saveM2MToken = createAction(ActionTypes.SaveM2MToken,
  props<{ payload: { m2mToken: string } }>());
// Don't reset M2MToken
export const resetUserState = createAction(ActionTypes.ResetUserState);
