export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ANY = 'ANY'
}

export enum UserDefaultOperator {
  AND = 'and',
  OR = 'or',
  ANY = 'any'
}

export enum UserType {
  MANAGED = 'MANAGED',
  PASSWORDLESS = 'PASSWORDLESS',
  STANDARD = 'STANDARD'
}

export const UserKeyEntity = {
  name: 'name',
  email: 'email'
};
