import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGetGroupsParams } from '@app/core/models/group.model';
import { Observable } from 'rxjs';
import { environment as env } from '@environments/environment';
import { EntityApiBaseService } from './entity-api-base.service';

@Injectable({
  providedIn: 'root'
})
export class CoreGroupApiService {
  private GROUP_RESOURCE = 'groups';
  private MEMBERS_RESOURCE = 'members';

  constructor(
    private http: HttpClient,
    private entityApiBaseService: EntityApiBaseService,
  ) { }

  /**
 * Get all groups that matches query parameters, return a full model
 * @param params Query parameters to search for groups
 * @returns Http response of group list in full model
 */
  getGroups(m2mToken: string, params?: IGetGroupsParams ) {
    const url = `${env.coreEntityApiBase.url}/${this.GROUP_RESOURCE}`;
    if (m2mToken) {
      return this.entityApiBaseService.getAll(url, params, m2mToken);
    }
    return this.entityApiBaseService.getAll(url, params);
  }

  /**
   * Add members to a group
   * @param groupId The group id
   * @param params The array of member's ids to be added to this group member array
   * @returns Http response of request status
   */
  addGroupMembers(groupId: string, params: Array<string>, newToken: string): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.GROUP_RESOURCE}/${groupId}/${this.MEMBERS_RESOURCE}`;
    if (newToken) {
      let httpHeader = new HttpHeaders();
      httpHeader = httpHeader.append('newToken', newToken);
      return this.http.patch(url, params, { observe: 'response', headers: httpHeader });
    }
    return this.http.patch(url, params, { observe: 'response'});
  }
}
