/* angular */
import { createReducer, on } from '@ngrx/store';

/* services */

/* actions */
import * as ApplicationDataStoreActions from './application-data-store.actions';
import { initialState } from './application-data-store.interfaces';

/* state key */
export const featureKey = 'application';


export const reducer = createReducer(
  initialState,
  /* reducer functions for actions */
  on(ApplicationDataStoreActions.initApplication, (state, action) => ({
    ...state,
    loading: true
  })),

  on(ApplicationDataStoreActions.getApplicationsSuccessfully, (state, action) => ({
    ...state,
    applications: action.payload.applications,
  })),

  on(ApplicationDataStoreActions.getRolesByAppIdSuccessfully, (state, action) => ({
    ...state,
    roles: action.payload.roles,
  })),
);
