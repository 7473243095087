import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '@environments/environment';
import { appName } from '@environments/shared';

@Injectable({
  providedIn: 'root'
})
export class DataDogService {
  constructor(
  ) {
    if (environment.name !== 'local') {
      datadogRum.init({
        applicationId: environment.datadog.applicationId,
        clientToken: environment.datadog.clientToken,
        site: environment.datadog.site,
        env: environment.authEnv,
        service: appName,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
      });
    }
  }
}
