import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {
  @Input() searchFormControl = new FormControl('');
  @Input() placeHolder = '';
  @Output() searchEmitter = new EventEmitter();
  constructor() { }

  resetSearchBox() {
    this.searchFormControl.setValue('');
  }

  onSubmit() {
    this.searchEmitter.emit();
  }
}
