import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatListModule } from '@angular/material/list';
import {MatChipsModule} from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {MatGridListModule} from '@angular/material/grid-list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DetailListComponent } from './components/detail-list/detail-list.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SearchComponent } from './components/search/search.component';
import { MatDividerModule } from '@angular/material/divider';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ZonarUiSearchableDropdownModule } from '@zonar-ui/searchable-dropdown';
import { TokenDialogComponent } from './dialogs/token-dialog/token-dialog.component';


@NgModule({
  declarations: [
    DetailListComponent,
    SearchComponent,
    PageNotFoundComponent,
    TokenDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatTableModule,
    MatInputModule,
    MatButtonModule,
    FlexLayoutModule,
    MatPaginatorModule,
    MatListModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatGridListModule,
    MatDialogModule,
    MatCardModule,
    RouterModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    ZonarUiSearchableDropdownModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    RouterModule,
    FlexLayoutModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    ZonarUiSearchableDropdownModule,

    /** Components */
    DetailListComponent,
    SearchComponent,
    PageNotFoundComponent,
    TokenDialogComponent
  ],
  providers: []
})
export class SharedModule { }
