import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  defaultToken = '';
  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    this.defaultToken = request.headers.get('authorization') || '';
    const newToken = request.headers.get('newToken') || '';
    let httpRequest = request.clone({});
    if (newToken) {
      httpRequest = httpRequest.clone({ headers: httpRequest.headers.delete('newToken') });
      httpRequest = httpRequest.clone({
        setHeaders: { Authorization: `Bearer ${newToken}`, 'IS-ADMIN': 'true' }
      });

    }

    return next.handle(httpRequest)

      .pipe(
        map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
          if (evt instanceof HttpResponse) {
            httpRequest = httpRequest.clone({
              setHeaders: { Authorization: this.defaultToken }
            });
            httpRequest = httpRequest.clone({ headers: httpRequest.headers.delete('IS-ADMIN') });
          }
          return evt;
        }),
        catchError((err: any) => {
          httpRequest = httpRequest.clone({
            setHeaders: { Authorization: this.defaultToken }
          });
          httpRequest = httpRequest.clone({ headers: httpRequest.headers.delete('IS-ADMIN') });
          return throwError(err);
        })
      );
  }
}
