/* angular */
import { DetailRow } from '@app/core/models/detail-list.model';
import { UserProfile } from '@app/core/models/user.model';
import { createReducer, on } from '@ngrx/store';

/* actions */
import * as UsersDataStoreActions from './users-data-store.actions';
import { User } from 'user-api-sdk/lib/models/user';

/* state key */
export const featureKey = 'users';

/* state interface */

export interface UsersDataStore {
  loading: boolean,
  userSelected: {
    error: any,
    userInfo: User | null,
    userDetailsList: DetailRow[] | null,
  },
  userEditing: {
    userProfile?: UserProfile | null
    error: any
  },
  m2mToken: string | null
}


/* initial state */
export const initialState: UsersDataStore = {
  loading: false,
  userSelected: {
    error: null,
    userInfo: null,
    userDetailsList: null,
  },
  userEditing: {
    userProfile: null,
    error: null
  },
  m2mToken: null
};

export const reducer = createReducer(
  initialState,
  on(UsersDataStoreActions.getUserByEmail, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(UsersDataStoreActions.getUserByEmailSuccessfully, (state, action) => {
    return {
      ...state,
      loading: false,
      userSelected: {
        error: null,
        userInfo: action.payload.userEditing?.userInfo,
        userDetailsList: action.payload.userEditing?.userDetailsList,
      }
    };
  }),
  on(UsersDataStoreActions.getUserByEmailFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      userSelected: {
        error: action.payload.error,
        userInfo: null,
        userDetailsList: null,
      }
    };
  }),
  on(UsersDataStoreActions.createUserProfile, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(UsersDataStoreActions.createUserProfileSuccessfully, (state, action) => {
    return {
      ...state,
      loading: false,
      userEditing: {
        ...state.userEditing,
        userProfile: action.payload.userProfile,
        error: null
      }
    };
  }),
  on(UsersDataStoreActions.createUserProfileFailure, (state, action) => {
    return {
      ...state,
      loading: false,
      userEditing: {
        ...state.userEditing,
        error: action.payload.error,
        userProfile: null
      }
    };
  }),
  on(UsersDataStoreActions.saveM2MToken, (state, action) => {
    return {
      ...state,
      m2mToken: action.payload.m2mToken
    };
  }),
  // Don't reset M2MToken
  on(UsersDataStoreActions.resetUserState, (state) => {
    return {
      ...state,
      loading: false,
      userSelected: {
        error: null,
        userInfo: null,
        userDetailsList: null,
      },
      userEditing: {
        userProfile: null,
        error: null
      }
    };
  }),
);


