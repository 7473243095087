import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment as env } from '@environments/environment';
import {  UserProfile } from '@app/core/models/user.model';


@Injectable({
  providedIn: 'root'
})
export class CoreUserApiService {
  private USER_PROFILE_RESOURCE = 'userprofiles';


  constructor(
    private http: HttpClient,
  ) { }

  createUserProfile(params: UserProfile, newToken?: string) {
    const url = `${env.coreEntityApiBase.url}/${this.USER_PROFILE_RESOURCE}`;
    if (newToken) {
      let httpHeader = new HttpHeaders();
      httpHeader = httpHeader.append('newToken', newToken);
      return this.http.post(url, params, { observe: 'response', headers: httpHeader });
    }
    return this.http.post(url, params, { observe: 'response' });
  }

}
