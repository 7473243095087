import { Injectable } from '@angular/core';
import { act, Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, mergeMap } from 'rxjs/operators';

/* actions */
import * as ApplicationActions from './application-data-store.actions';
import { ApplicationsService } from 'product-api-sdk';
import { ApplicationModel } from 'product-api-sdk/lib/models/application-model';
import { RoleModel } from 'product-api-sdk/lib/models/role-model';

@Injectable()
export class ApplicationDataStoreEffects {
  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private applicationsService: ApplicationsService
  ) { }

  getApplications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.getApplications),
      mergeMap(action => {
        return this.applicationsService.listAllApplicationsApplicationsGet().pipe(
          mergeMap((applications: Array<ApplicationModel>) => {
            return [ApplicationActions.getApplicationsSuccessfully({
              payload: {
                applications:  applications || [],
              }
            })];
          })
        );
      })
    )
  );

  getRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.getRolesByAppId),
      mergeMap(action => {
        return this.applicationsService.getApplicationRolesApplicationsApplicationIdRolesGet({ application_id: action.payload.appId as string }).pipe(
          mergeMap((roles: Array<RoleModel>) => {
            return [ApplicationActions.getRolesByAppIdSuccessfully({
              payload: {
                roles: roles || [],
              }
            })];
          })
        );
      })
    )
  );
}
