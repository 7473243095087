import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-token-dialog',
  templateUrl: './token-dialog.component.html',
  styleUrls: ['./token-dialog.component.scss']
})
export class TokenDialogComponent implements OnInit {
  token = null;
  authURL = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TokenDialogComponent>,
  ) { }

  ngOnInit(): void {
    this.authURL = environment.auth.authURL;
    this.token = this.data?.token;
  }

  onCancel() {
    this.dialogRef.close({isCancel: true, token: null});
  }

  onConfirm() {
    this.dialogRef.close({isCancel: false, token: this.token});
  }
}
