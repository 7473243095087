import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

/* actions */
import * as GroupActions from './groups-data-store.actions';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { CoreGroupApiService } from '@app/core/services/api/core-group-api.service';

@Injectable()
export class GroupsDataStoreEffects {
  constructor(
    private actions$: Actions,
    private coreGroupApiService: CoreGroupApiService
  ) { }

  getGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupActions.getGroups),
      mergeMap((action) => {
        return this.coreGroupApiService.getGroups(action.payload.newToken, action.payload.params).pipe(
          switchMap((res: any) => {
            return [GroupActions.getGroupsSuccess({
              payload: {
                groups: res || []
              }
            })];
          }),
          catchError((err: any) => {
            return [GroupActions.getGroupsFailure({
              payload: { error: err }
            })];
          })
        );
      })
    )
  );

  assignMemberToGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupActions.assignMemberToGroup),
      mergeMap((action) => {
        const {groupId, params, newToken} = action.payload;
        return this.coreGroupApiService.addGroupMembers(groupId, params, newToken).pipe(
          switchMap((res: any) => {
            return [GroupActions.assignMemberToGroupSuccess()];
          }),
          catchError((err: any) => {
            return [GroupActions.assignMemberToGroupFailure({
              payload: { error: err }
            })];
          })
        );
      })
    )
  );
}
