import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EntityApiBaseService {

  private readonly perPage = 100;

  constructor(
    private http: HttpClient
  ) { }

  getAll(url: string, params: any, m2mToken: string = '') {
    const firstPageParams: HttpParams = new HttpParams({ fromObject: { ...params, page: 1, per_page: this.perPage } });
    const options: any = {
      params: firstPageParams,
      observe: 'response'
    };
    let httpHeader = new HttpHeaders();
    if (m2mToken) {
      httpHeader = httpHeader.append('newToken', m2mToken);
      options['headers'] = httpHeader;
    }

    const firstPage = this.http.get(url, options);

    return firstPage.pipe(
      switchMap((res: any) => {
        const pageCount = +res.headers.get('x-page-count');
        const remainingPages = [];
        for (let i = 2; i <= pageCount; i++) {
          const nextPageParams: HttpParams = new HttpParams({ fromObject: { ...params, page: i, per_page: this.perPage } });
          options.params = nextPageParams;
          const nextPage = this.http.get(url, options);
          remainingPages.push(nextPage);
        }
        return forkJoin([of(res), ...remainingPages]) as Observable<Array<HttpResponse<any>>>;
      }),
      map((allResponses) => {
        return allResponses.reduce((acc: any, res) => [...acc, ...res.body], []);
      }));
  }
}
