import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { DataStoreModule } from './shared/data-store/data-store.module';
import { SharedModule } from './shared/shared.module';

// Patern Library Modules
import { ZonarOwnerIdInterceptor, ZonarUiAuthModule, ZonarUiPermissionsModule } from '@zonar-ui/auth';
import { ZonarUIMaterialModule } from '@zonar-ui/material';

// Local Modules
import { environment } from '@environments/environment';
import { permissions } from '@environments/shared';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexModule } from '@angular/flex-layout';
import { SidenavModule as ZonarUiSidenavModule } from '@zonar-ui/sidenav';
import { HttpRequestInterceptor } from './core/interceptors/http-request.interceptor';
import { UserApiModule } from 'user-api-sdk';
import { ProductApiModule } from 'product-api-sdk';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    UserApiModule.forRoot({
      rootUrl: environment.coreEntityApiBase.url
    }),
    ProductApiModule.forRoot({
      rootUrl: environment.coreEntityApiBase.url
    }),
    BrowserAnimationsModule,
    AppRoutingModule,
    ZonarUiAuthModule,
    ZonarUiPermissionsModule,
    ZonarUIMaterialModule,
    ZonarUiSidenavModule,
    SharedModule,
    CoreModule,
    DataStoreModule,
    HttpClientModule,
    FlexModule,
    AuthModule.forRoot({
      clientId: environment.auth.clientID,
      domain: environment.auth.domain,
      audience: environment.auth.audience,
      redirect_uri: window.location.origin,
      scope: 'openid profile email',
      useRefreshTokens: true,
      max_age: 36000,
      cacheLocation: 'localstorage',
      httpInterceptor: {
        allowedList: [
          // core entity api base urls. these are used by the auth module to make calls to the user-api
          `${environment.coreEntityApiBase.url}/*`,
          `${environment.coreEntityApiBase.url}beta2/*`, // TODO: this is temporary workaround for setting API
        ]
      }
    }),
  ],
  providers: [
    // Auth settings
    { provide: 'applicationId', useValue: environment.auth.applicationId },
    { provide: 'defaultPermPrefix', useValue: permissions.defaultPermPrefix },
    { provide: 'routePermissions', useValue: permissions.routes },
    { provide: 'redirectUrl', useValue: permissions.defaultRedirectUrl },
    { provide: 'logoutReturnToUrl', useValue: environment.appUrl },
    { provide: 'environment', useValue: environment.authEnv },
    { provide: 'region', useValue: environment.region },

    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ZonarOwnerIdInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    ZonarUiAuthModule,
    ZonarUiPermissionsModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
