import { SidenavItemModel } from "@app/core/models/shared.model";
import { environment } from "@environments/environment";
import { ADMIN_PLUS_BASE_URL, PRODUCT_CONFIGURATION_BASE_URL } from "./base-urls";

export const SidenavHeaderConfig = {
  displayName: true, // if we want to display name of the user
  avatarImageUrl: null // if we want to show a specific avatar picture e.g. user's avatar
};

export const SidenavMenuConfig: any[] = [
  {
    text: 'Admin+',
    icon: 'home',
    route: '/',
    routerLink: ADMIN_PLUS_BASE_URL[environment.region][environment.authEnv].route,
    appId: ADMIN_PLUS_BASE_URL[environment.region][environment.authEnv].appId,
    children: [],
    isExpanded: false,
    routeLevel: 1,
  },
  {
    text: 'Prod-Conf',
    icon: 'production_quantity_limits',
    appId: PRODUCT_CONFIGURATION_BASE_URL[environment.region][environment.authEnv]?.appId,
    route: '/',
    routerLink: PRODUCT_CONFIGURATION_BASE_URL[environment.region][environment.authEnv]?.route,
    routeLevel: 1,
    isExpanded: false,
    children: [],
  },
  {
    text: 'Core Admin',
    icon: 'home',
    appId: environment.auth.applicationId,
    routeLevel: 1,
    isExpanded: true,
    route: '/',
    children: [
      {
        text: 'API Credentials',
        icon: 'home',
        routerLink: '/credentials',
        route: 'credentials',
        routeLevel: 2,
        isExpanded: false,
        children: []
      }
    ],
  },
];

export const SidenavFooterConfig = {
  items: [
    {
      text: 'Help',
      icon: 'help',
      route: `${environment?.sidenavHelpUrl}`,
    }
  ]
};
