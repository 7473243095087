export interface AppBaseUrl {
  [key: string]: EnvBaseUrl;
}

export interface EnvBaseUrl {
  [key: string]: AppConfig;
}

export interface AppConfig {
  route: string;
  appId: string;
}

export const ADMIN_PLUS_BASE_URL: AppBaseUrl = {
  NA: {
    development: {
      route: 'https://adminplus.dev.zonarsystems.net',
      appId: 'b9203a2f-1a72-42ff-bcba-b7c017a1d49d'
    },
    qa: {
      route: 'https://adminplus.qa.dev.zonarsystems.net',
      appId: '7bd2fe46-cec2-435a-a42d-dff34669605e'
    },
    production: {
      route: 'https://adminplus.production.zonarsystems.net',
      appId: '5e6403e5-b8db-4637-ab3a-3bec41efd725'
    },
  }
};

export const PRODUCT_CONFIGURATION_BASE_URL: AppBaseUrl = {
  NA: {
    development: {
      route: 'https://product-configuration.dev.zonarsystems.net/',
      appId: '90c3a106-2b2d-460b-a4d4-27ba2f6c8fd6'
    },
    qa: {
      route: 'https://product-configuration.qa.zonarsystems.net',
      appId: 'ab9ab40a-38f2-4d15-af42-fc303a9af739'
    },
    production: {
      route: 'https://product-configuration.production.zonarsystems.net',
      appId: '1ab5b1ba-e6d2-47c8-8985-5aeb5dae611b'
    },
  }
};
