<div class="token-dialog-container" fxLayout="column" fxLayoutAlign="space-between start">
  <div fxLayout="row" fxLayoutAlign="start" class="title-page">
    <span class="title">Add Token</span>
  </div>

  <div class="token-dialog-content" fxLayout="row" fxLayoutAlign="space-between start">
    <div fxFlex="60" class="token-dialog" fxLayout="column" fxLayoutAlign="start">
      <textarea class="token" name="token" rows="20" [(ngModel)]="token"></textarea>
    </div>

    <div fxFlex="40" class="token-dialog" fxLayout="column" fxLayoutAlign="start">
      <span fxLayoutAlign="start center" class="helptext-item"> You need to add an m2m (machine to machine) token in order to create a user profile</span>
      <span fxLayoutAlign="start center" class="helptext-item">Get&nbsp; <b> m2m token </b>&nbsp; from &nbsp;<a [href]="authURL" target="_blank"> Auth-URL</a></span>
      <span fxLayoutAlign="start center" class="helptext-item"><b>Bearer </b> &nbsp;is not required</span>
      <div class="helptext-item">
        <span >
          If you have an issue, please navigate to the help page: <a href="https://zonarsystems.atlassian.net/wiki/spaces/ZSS/pages/137710600395/Core+Admin+Application">Core Admin Application</a>
        </span>
      </div>
    </div>
  </div>

  <div class="dialog-button" fxLayout="row" fxLayoutAlign="end end">
    <button mat-stroked-button type="submit" (click)="onCancel()">Cancel</button>
    <button mat-flat-button type="submit" (click)="onConfirm()">Save</button>
  </div>

</div>