import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, catchError, switchMap, map } from 'rxjs/operators';
import * as UsersDataStoreActions from './users-data-store.actions';

/* actions */
import { HttpResponse } from '@angular/common/http';
import { UserProfile } from '@app/core/models/user.model';
import { buildKeyValue } from '@app/core/utils';
import { UserKeyEntity } from '@app/core/consts/user.const';
import { UsersService } from 'user-api-sdk';
import { User } from 'user-api-sdk/lib/models/user';
import { CoreUserApiService } from '@app/core/services/api/core-user-api.service';

@Injectable()
export class UsersDataStoreEffects {
  newLineReg = /\r\n|\n/;

  constructor(
    private actions$: Actions,
    private usersService: UsersService,
    private userApiService: CoreUserApiService
  ) { }


  getUserByEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersDataStoreActions.getUserByEmail),
      mergeMap((action) => {
        return this.usersService.getAllUsersApi({ email: `${action.payload?.email?.trim() || null}` }).pipe(
          map((response: Array<User>) => {
            return response && response[0] ? response[0] : {};
          }),
          switchMap((user: User) => {
            let userFound = {} as any;
            if (Object.keys(user).length) {
              userFound = user;
              userFound.name = `${userFound.firstName ?? ''} ${userFound.middleName ?? ''} ${userFound.lastName ?? ''}`;
            }

            return [UsersDataStoreActions.getUserByEmailSuccessfully({
              payload: {
                userEditing: {
                  userInfo: userFound,
                  userDetailsList: buildKeyValue(userFound, UserKeyEntity)
                }
              }
            })];
          }),
          catchError((err: any) => {
            return [UsersDataStoreActions.getUserByEmailFailure({
              payload: { error: err }
            })];
          })
        );
      })
    )
  );

  createUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersDataStoreActions.createUserProfile),
      mergeMap((action) => {
        const { userProfile, newToken } = action.payload;
        return this.userApiService.createUserProfile(userProfile, newToken).pipe(
          map((response: HttpResponse<any>) => {
            return response.body ? response.body : {};
          }),
          switchMap((userProfile: UserProfile) => {
            return [UsersDataStoreActions.createUserProfileSuccessfully({
              payload: {
                userProfile
              }
            })];
          }),
          catchError((err: any) => {
            return [UsersDataStoreActions.createUserProfileFailure({
              payload: { error: err }
            })];
          })
        );
      })
    )
  );
}