<div class="wrapper"
     fxLayout="column">
  <div fxLayout="row">
    <zui-sidenav [sidenavParams]="sidenavParams"
                 [header]="sidenavHeader"
                 [menu]="sidenavMenu"
                 [footer]="sidenavFooter"
                 (toggled)="onSidenavToggled($event)"
                 (emitSelectedCompany)="setCurrentCompanyByFormSelection($event)">

      <div class="content-container app-bg-color"
           fxFlex>
        <zui-sidenav-header class="header-bar-sidenav"
                            [title]="'Core Admin'"
                            (menuToggled)="onSideNavMobileMenuButtonToggled($event)"></zui-sidenav-header>
        <router-outlet></router-outlet>
      </div>
    </zui-sidenav>
  </div>
</div>
