/* Angular */
import { createAction, props } from '@ngrx/store';
import { ApplicationModel } from 'product-api-sdk/lib/models/application-model';
import { RoleModel } from 'product-api-sdk/lib/models/role-model';

/* action types */
export enum ActionTypes {
  Init = '[APPLICATION] Init',
  GetApplications = '[APPLICATION] GetApplications',
  GetApplicationsSuccessfully = '[APPLICATION] GetApplicationsSuccessfully',
  GetApplicationsFailure = '[APPLICATION] GetApplicationsFailure',
  GetRolesByAppId = '[APPLICATION] GetRolesByAppId',
  GetRolesByAppIdSuccessfully = '[APPLICATION] GetRolesByAppIdSuccessfully',
  GetRolesByAppIdFailure = '[APPLICATION] GetRolesByAppIdFailure',
}

/* actions */
export const initApplication = createAction(ActionTypes.Init);
export const getApplications = createAction(ActionTypes.GetApplications);
export const getApplicationsSuccessfully = createAction(ActionTypes.GetApplicationsSuccessfully, props<{ payload: { applications: ApplicationModel[] | null } }>());
export const getApplicationsFailure = createAction(ActionTypes.GetApplicationsFailure, props<{ payload: { error: any } }>());

export const getRolesByAppId = createAction(ActionTypes.GetRolesByAppId, props<{ payload: { appId: string | null } }>());
export const getRolesByAppIdSuccessfully = createAction(ActionTypes.GetRolesByAppIdSuccessfully, props<{ payload: { roles: RoleModel[] | null } }>());
export const getRolesByAppIdFailure = createAction(ActionTypes.GetRolesByAppIdFailure, props<{ payload: { error: any } }>());

