import { ApplicationModel } from "product-api-sdk/lib/models/application-model";
import { RoleModel } from "product-api-sdk/lib/models/role-model";

/* state interface */
export interface ApplicationState {
  applications: ApplicationModel[] | null;
  roles: RoleModel[] | null;
  loading: boolean | null;
}

/* initial values */
export const initialState: ApplicationState = {
  applications: null,
  roles: null,
  loading: null,
};
